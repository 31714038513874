/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

@import 'globals.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

// OneTrust
#onetrust-consent-sdk {
  // remove icon by default (will show after, only when it should be visible)
  &:not(.visible) {
    display: none;
  }
  // remove icon when printing
  @media print {
    display: none;
  }
  svg {
    display: inline;
  }
}

// Remove arrows from number inputs
@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
  html {
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
    font-size: $font-size;
    @apply bg-white;

    @media (min-width: $width-150) and (min-height: $height-150) {
      font-size: floor($font-size / 1.5);
    }
    @media (min-width: $width-125) and (min-height: $height-125) {
      font-size: floor($font-size / 1.25);
    }
    @media (min-width: $width-100) and (min-height: $height-100) {
      font-size: $font-size;
    }
  }
  ul,
  ol {
    list-style: revert;
  }
}

@layer utilities {
  // Text
  .text-responsive-xs {
    @apply text-2xs lg:text-xs;
  }
  .text-responsive-sm {
    @apply text-2xs lg:text-xs xl:text-sm;
  }
  .text-responsive-base {
    @apply text-xs lg:text-sm xl:text-base;
  }
  .text-responsive-lg {
    @apply text-sm lg:text-base xl:text-lg;
  }
  .text-responsive-xl {
    @apply text-base lg:text-lg xl:text-xl;
  }
  .text-responsive-2xl {
    @apply text-lg lg:text-xl xl:text-2xl;
  }
  .text-responsive-3xl {
    @apply text-xl lg:text-2xl xl:text-3xl;
  }

  // Background
  .bg-entyvio-gradient {
    @apply bg-gradient-to-r from-entyvio-red to-entyvio-purple;
  }
}

@layer components {
  .button {
    @apply flex justify-center items-center border rounded px-6 xl:px-9
      h-10 xl:h-13
      text-responsive-lg
      disabled:opacity-50;

    &.primary {
      @apply border-brand-red bg-brand-red text-white;
    }
    &.secondary {
      @apply border-brand-red bg-white text-brand-red;
    }
    &.tertiary {
      @apply border-black bg-white text-black;
    }
    &.quaternary {
      @apply border-brand-gray bg-white text-black;
    }
  }

  .contained-content {
    max-width: 80vw;
  }

  .text-heading {
    @apply my-5 font-bold text-xl md:text-2xl xl:text-3xl text-brand-red;
  }
  .text-header {
    @apply text-lg md:text-xl xl:text-2xl;
  }

  /*
    this class is used to undo the reset of styles, so the localization team can
    use HTML on their translations
  */
  .html-output {
    h2 {
      @apply mt-4 mb-2 text-responsive-xl font-bold;
    }
    p {
      @apply my-4;
    }
    ol,
    ul {
      @apply ms-6;

      li {
        @apply my-2;
      }
    }
    a {
      @apply text-sky-700 underline;
    }
  }
}
