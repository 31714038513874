/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

.page.login-selection {
  .page-contents {
    --header-height: 0px;
    --footer-height: 80px;
  }
}
