/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

@import '../globals.scss';

/* sticky footer mixins */
@mixin sticky-footer {
  .page-contents {
    --header-height: 74px;
    --footer-height: 160px;
    --notification-banner-height: 0px;
    --demo-banner-height: 0px;

    display: flex;
    flex-direction: column;
    min-height: calc(
      100vh - var(--header-height) - var(--notification-banner-height) -
        var(--demo-banner-height)
    );
    padding-bottom: var(--footer-height);
  }

  footer {
    bottom: 0;
    position: absolute;
  }
}

@media not print {
  @media (min-width: $media-lg) {
    @include sticky-footer;

    .notification-banner ~ div .page-contents {
      --notification-banner-height: 40px;
    }

    .demo-banner ~ div .page-contents {
      --demo-banner-height: 76px;
    }
  }

  .auth {
    @include sticky-footer;
  }
}

.page-contents {
  @media (min-width: $media-sm) {
    --footer-height: 100px;
    --header-height: 124px;
  }
  @media (min-width: $width-150) and (min-height: $height-150) {
    --footer-height: 50px;
    --header-height: 80px;
  }
  @media (min-width: $width-125) and (min-height: $height-125) {
    --footer-height: 60px;
    --header-height: 96px;
  }
  @media (min-width: $width-100) and (min-height: $height-100) {
    --footer-height: 80px;
    --header-height: 124px;
  }
}
